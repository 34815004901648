import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login/Login.vue'
import Logins from '../views/Login/Logins.vue'
import store from "@/store";
import axios from 'axios'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/login',
  //   name: '登录',
  //   component: Login
  // },
  {
    path: '/logins',
    name: '登录',
    component: Login
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404/404.vue')
  },
  { path: '/Index', name: 'Index', component: () => import('../views/Layout/Index.vue'), redirect: "/home", children: [
    { path: '/devices', name: '设备管理', component: () => import("../views/Layout/Main/DbList/DbList.vue")},
    { path: '/time', name: '时间段管理', component: () => import("../views/Layout/Main/DbList/Time.vue")},
    { path: '/qclist', name: '器材管理', component: () => import("../views/Layout/Main/DbList/QcList.vue")},
    { path: '/gui', name: '柜门列表', component: () => import("../views/Layout/Main/GuiList/GuiList.vue")},
    { path: '/jlist', name: '借还记录', component: () => import("../views/Layout/Main/JHlist/JHlist.vue")},
    { path: '/partment', name: '部门人员信息', component: () => import("../views/Layout/Main/Partment/Partment.vue")},

    { path: '/person', name: '个人信息', component: () => import('../views/Layout/Main/Person/Person.vue')},
    { path: '/user', name: '用户管理', component: () => import('../views/Layout/Main/Admin/User.vue')},
    { path: '/mian', name: '待面试', component: () => import('../views/Layout/Main/Admin/Mianshi.vue')},
    { path: '/waitpay', name: '待缴费', component: () => import('../views/Layout/Main/Admin/DaiJiaoFei.vue')},
    { path: '/kclist', name: '课程列表', component: () => import('../views/Layout/Main/Admin/kcList.vue')},
    { path: '/datalist', name: '数据列表', component: () => import('../views/Layout/Main/DataList/DataList.vue')},
    { path: '/classsetting', name: '课程设置', component: () => import('../views/Layout/Main/Admin/SetKcs.vue')},
    { path: '/selectsetting', name: '选课时间', component: () => import('../views/Layout/Main/Admin/SelectTime.vue')},

    { path: '/classlist', name: '班级报表', component: () => import('../views/Layout/Main/DbList/BaoBiaoClass.vue')},
    { path: '/peoplelist', name: '人员报表', component: () => import('../views/Layout/Main/DbList/BaoBiaoPeople.vue')},
    { path: '/tiaolist', name: '调课记录', component: () => import('../views/Layout/Main/DbList/BaoBiaoTiao.vue')},
    { path: '/tuilist', name: '退课记录', component: () => import('../views/Layout/Main/DbList/BaoBiaoTuiKe.vue')},
  ] },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 提供一个重置路由的方法
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
}


// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
  const storeMenus = localStorage.getItem("menus");
  if (storeMenus) {

    // 获取当前的路由对象名称数组
    const currentRouteNames = router.getRoutes().map(v => v.name)
    if (!currentRouteNames.includes('Manage')) {
      // 拼装动态路由
      const manageRoute = { path: '/Index', name: 'Index', component: () => import('../views/Layout/Index.vue'), redirect: "/home", children: [
          // { path: '/person', name: '个人信息', component: () => import('../views/Layout/Main/Person/Person.vue')},
          // { path: '/user', name: '用户列表', component: () => import('../views/Layout/Main/Admin/User.vue')},
        ] }
      // const manageRoute = {
      //   path:"/person",
      //   component: ()=>import('../components/Person/Index.vue'),
      // }
      const menus = JSON.parse(storeMenus)
      menus.forEach(item => {
        // console.log(menus);
        if (item.tabText) {  // 当且仅当path不为空的时候才去设置路由   item.path.replace("/", "")
          let itemMenu = { path: item.tabText, name: item.displayText, component: () => import('../views/Layout/Main' + item.tabText + '.vue')}
          // console.log(itemMenu);
          manageRoute.children.push(itemMenu)
        } else if(Array.isArray(item.children)) {
          item.children.forEach(item => {
            if (item.tabText) {
              // item.path.replace("/", "")
              let itemMenu = { path: item.tabText, name: item.displayText, component: () => import('../views/Layout/Main' + item.tabText + '.vue')}
              // console.log(itemMenu);
              manageRoute.children.push(itemMenu)
            }
          })
        }
      })
      // console.log(manageRoute)
      // 动态添加到现在的路由对象中去
      router.addRoute(manageRoute)
    }

  }
}

// 重置我就再set一次路由
// setRoutes()


// 路由守卫
// 路由守卫
// 路由守卫
router.beforeEach(async(to, from, next) => {
  localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
  store.commit("setPath")
/*   if (to.query['userId']) {
    // debugger
    // axios({
    //     url: "/api11/api/logins",
    //     method: "post",
    //     data: {userId: to.query['userId']},
    //     headers:{
    //         "Content-Type": "application/x-www-form-urlencoded"
    //     }
    // }).then(res => res.data)
    // .then(res => {
    //   if (res.code == 200) {
    //     localStorage.setItem("CurUser", JSON.stringify({username: to.query['username'], userId: to.query['userId']}));
    //     localStorage.setItem("token", res.token)
    //     next("/person")
    //   }
    // })
    const res = await axios({
      url: "/api11/api/logins",
      method: "post",
      data: { userId: to.query['userId'] },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    if (res.data.code === 200) {
      localStorage.setItem("CurUser", JSON.stringify({ username: to.query['username'], userId: to.query['userId'] }));
      localStorage.setItem("token", res.data.token);
      next("/person");
    } else {
      // next("/login");
    }
  } */
  // 未找到路由的情况
  if (!to.matched.length) {
    // const storeMenus = localStorage.getItem("menus")
    next("/logins")
  }
  
  next()

})


export default router
